import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'

// Images
import Location from 'img/location.inline.svg'

const FormWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.primary};
  @media (min-width: 1200px) {
    margin-top: -9rem;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    position: relative;
    margin-bottom: 0.75rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.primary};
    padding: 10px 15px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    top: 5px;
    right: 8px;
    color: tomato;
    opacity: 0.5;
    font-size: 10px;
  }

  & .form-duuf-submit {
    ${ButtonSecondaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-something-wrong {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.primary} !important;
  }
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 28px;
  }
`

const Content = styled(ParseContent)`
  & p {
    line-height: 32px !important;
  }
  & a:hover {
    text-decoration: underline;
  }
`

const Image = styled(Plaatjie)`
  width: 21px;
  height: 21px;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
`

const Socials = styled.div`
  & a {
    font-size: 0;
  }
`

const GoogleMaps = styled.a`
  & span {
    color: ${({ theme }) => theme.color.primary};
  }

  & svg {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.75;
  }

  &:hover {
    & svg {
      opacity: 1;
    }

    & span {
      text-decoration: underline;
    }
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-md-6 mb-5 mb-md-0">
              <Content content={fields.description} />
              <GoogleMaps
                href={fields.googlemaps?.url}
                target="_blank"
                rel="noreferrer"
                className="d-flex align-items-center mb-5 mt-1"
              >
                <Location className="me-3" />
                <span>{fields.googlemaps?.title}</span>
              </GoogleMaps>
              <Content content={fields.descriptiontwo} />
              <Socials>
                {fields.socials?.map((social, index: number) => (
                  <a
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    href={social?.link?.url}
                    className="me-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image image={social?.icon} alt="" />
                    {social?.link?.title}
                  </a>
                ))}
              </Socials>
            </div>
            <div className="col-md-6">
              <FormWrapper className="px-sm-5 px-4 py-4">
                <Title content={fields.title} className="mb-4" />
                <StyledFormDuuf id={1} />
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
